<template>
<div v-if="current_view_style == 2" class="flight_tab_content pt-3 pb-1">
    <div class="booking_modal_after" v-on:click="remove_modal()"></div>

  <div class="hide_me box_1">
    <div class="bg_silver rounded_10 d-flex align-items-center">
        <div class="d-wf0o1">
  <select class="nice_select float-none w-100" v-model="selected_flight_type" aria-label="Select flight type">
    <option v-for="flighttype in flight_type" :key="flighttype.id" :value="flighttype.id">
      {{ flighttype.type_name }}
    </option>
  </select>
</div>

<div class="d-wf0o1 me-4">
  <select class="nice_select float-none w-100" v-model="selected_flight_class" aria-label="Select flight class">
    <option v-for="(flightclass, index) in flight_class" :key="flightclass.id" :value="flightclass.id">
      {{ flightclass.class_name }}
    </option>
  </select>
</div>
      
    </div>
  </div>
                <div class="box_1">
                  <div class="bg_silver rounded_10 d-flex align-items-center">
                    <label class="w-100 position-relative">
                        <span class="d-block fs_14">Departure</span>
                        <input v-on:input="departure_search_keyword = $event.target.value" 
                               v-model="departure_search_keyword" 
                               @focus="activateAutocomplete(1)" 
                               type="text" id="inp_flight_1" 
                               placeholder="City or airport" />
                               <span class="clear_input_ticket" @click="clearLocationInput()"></span>
                        <div v-if="selected_departure_result_object_valid == false" class="invalid-feedback">
                            Location is required
                        </div>
                        <AutocompleteComponent ref="autocomplete_1" 
                                               :has_initial_value="initial_autocomplete_value"
                                               @autocomplete_result_selected="select_departure_result" 
                                               :type="'airport'" 
                                               :keyword="departure_search_keyword">
                        </AutocompleteComponent>
                    </label>
                    <button type="button" @click="swapDirections">
                        <img width="50%" src="/img/direction-icon.svg" alt="" />
                    </button>
                    <label class="w-100 position-relative">
                        <span class="d-block fs_14">Destination</span>
                        <input v-on:input="destination_search_keyword = $event.target.value"
                               @focus="activateAutocomplete(2)" 
                               v-model="destination_search_keyword" 
                               type="text" id="inp_flight_2" 
                               placeholder="City or airport" />
                        <span class="clear_input_ticket" @click="clearLocationInput2()">
                            <img src="/img/clear_cross.svg" alt="Clear icon" />
                        </span>
                        <div v-if="selected_destination_result_object_valid == false" class="invalid-feedback">
                            Location is required
                        </div>
                        <AutocompleteComponent ref="autocomplete_2" 
                                               :has_initial_value="initial_autocomplete_value"
                                               @autocomplete_result_selected="select_destination_result" 
                                               :type="'airport'" 
                                               :keyword="destination_search_keyword">
                        </AutocompleteComponent>    
                    </label>
                </div>
                </div>
                <div class="box_2">
                  <div class="bg_silver rounded_10 d-flex position-relative">
                    <label for="date_range3" v-on:click="show_modal('#date_range3')">
                        <span class="d-block fs_14">Depart</span>
                        <input autocomplete="off" :value="first_date" type="button" id="first_date" class="drp-selected-start" readonly="">
                    </label>
                    <span v-if="selected_flight_type !== 1" class="line"></span>
                    <label v-if="selected_flight_type !== 1" for="date_range3" v-on:click="show_modal('#date_range3')">
                        <span class="d-block fs_14">Return</span>
                        <input :class="{'has_error' : invalid_return_date}" autocomplete="off" :value="second_date" type="button" id="second_date" class="drp-selected-end" readonly="">
                    </label>
                    <input type="text" class="date_range3">   
                </div>
                <div id="date_range3" :class="{'booking_modal': !isMobile}" style="position:absolute;width: max-content; left: 350px;z-index:100">
                  <DatePicker :unique_id="unique_id" @first_date_changed="handle_first_flight_date" :range="selected_flight_type !== 1"
                              @second_date_changed="handle_second_flight_date" ref="date_picker_3">
                  </DatePicker>
              </div>
                </div>
                <div class="box_3">
                                <div class="bg_silver rounded_10 d-flex position-relative"
                                v-on:click="show_modal('#booking_modal_3')">
                                    <label>
                                        <span class="d-block fs_14">Passengers</span>
                                        <input readonly="" :value="passengers_to_display" ref="input_box" type="button">
                                    </label>
    
                                    <div id="booking_modal_3" class="booking_modal">
                                        <div class="booking_modal_sm position-absolute w-100 h-100 bg-red" v-on:click="remove_modal()"></div>
                                        <div v-for="(room, roomIndex) in rooms" class="shadow booking_box">
                                            <h5 class="fw-bold mb-3 fs_18">Room {{ roomIndex + 1 }}</h5>
                                            <div class="d-flex justify-content-between mb-3">
                                                <div class="me-3" v-for="passenger_type in passenger_types">
                                                    <p class="mb-0 fw_500">{{ passenger_type.type }}</p>
                                                    <p class="mb-0 fs_12">{{ passenger_type.description
                                                    }}</p>
                                                
                                                <div class="wrap_inc_dec">
                                                    <button class="decrement" v-on:click="removePassenger(passenger_type.id, room, null, 0)">-</button>
                                                    <input type="number" readonly="" class="numberInput" :value="getNumberOfPassengers(room, passenger_type.id)">
                                                    <button class="increment" v-on:click="addPassenger(passenger_type.id, room.id, 0)">+</button>
                                                </div>
                                            </div>
                                            </div>
                                            <div class="wrap_child_box">
       <div v-for="(child, childIndex) in roomChildPassengers(room)" :key="child.id" class="child_box d-flex flex-column gap-3 mb-3">
        <p class="mb-0 fw_500">Child {{ childIndex + 1 }}</p>
      
        <select class="nice_select float-none w-100" @change="change_child_age(child, $event.target.value)">
         <option disabled="" value="">Age at time of travel</option>
         <!-- Infant 0-11 months -->
         <option value="0-11">Infant 0-11 months</option>
         <!-- Infant 12-23 months -->
         <option value="12-23">Infant 12-23 months</option>
         <!-- Children 2-12 years -->
         <option v-for="n in 11" :key="n" :value="n">{{ n + 1 }} years</option>
      </select>
       </div>
    </div>
    
                                            <div class="d-flex justify-content-end position-absolute bottom-20 right-20 z-3">
                                            <button v-if="roomIndex > 0" v-on:click.prevent="removeRoom(room)" class="me-5 mt-2 txt_pink fw-bold btn_reset fs_14 c_pointer1">
                                                Remove room
                                            </button>
                                            <button v-if="rooms.length < max_nr_of_rooms" v-on:click.prevent="addRoom" class="ms-4 mt-2 txt_pink fw-bold btn_reset fs_14 c_pointer1">
                                                Add a room
                                            </button>
                                        </div>
                                        <hr>
                                <h5 class="fw-bold mb-3 fs_18">Flight Type</h5>
                                
                                <div class="booking_class_btn">
          <button 
            v-for="(flighttype, index) in flight_type" 
            :key="flighttype.id"
            :class="{ 'active': selected_flight_type === flighttype.id }"
            @click="selected_flight_type = flighttype.id"
          >
            {{ flighttype.type_name }}
          </button>
        </div>
        <hr>
                                <h5 class="fw-bold mb-3 fs_18">Flight Class</h5>
                                <div class="booking_class_btn">
          <button 
            v-for="(flightclass, index) in flight_class" 
            :key="flightclass.id"
            :class="{ 'active': selected_flight_class === flightclass.id }"
            @click="selected_flight_class = flightclass.id"
          >
            {{ flightclass.class_name }}
          </button>
        </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                <button class="d-flex align-items-center justify-content-center" @click="search" type="submit">
                  <img width="24" src="/img/search-icon.svg" alt="" />
                  <span class="d-md-none d-inline-block text-white fw-semibold fs_14">Find</span>
              </button>
              </div>
    </template>
    
    <script>
    
    
    
    
    import AutocompleteComponent from "./AutocompleteComponent.vue";
    import DatePicker from "./DatePicker-new.vue";
    import { required } from "vuelidate/lib/validators";
    
    export default {
        components: { AutocompleteComponent, DatePicker },
    
        data: function () {
            return {
                isMobile: false,
                departure_search_keyword: "",
            destination_search_keyword: "",
                selected_departure_result_object_valid: true,
                selected_destination_result_object_valid: true,
                flight_passengers: [
                {
                    id: 1,
                    passenger_type: "Adult",
                    passenger_age_range: "18+",
                    passengers: [],
                    increments: 0,
                },
                /*         {
                            id: 2,
                            passenger_type: 'Student',
                            passenger_age_range: '18+',
                            passengers: [],
                            increments: 0,
                        },
                        {
                            id: 3,
                            passenger_type: 'Young People',
                            passenger_age_range: '12 - 17',
                            passengers: [],
                            increments: 0,
                        }, */
                {
                    id: 4,
                    passenger_type: "Children",
                    passenger_age_range: "2 - 11",
                    passengers: [],
                    increments: 0,
                },
                /*     {
                        id: 5,
                        passenger_type: 'Small children in their own seat',
                        passenger_age_range: 'Under 2',
                        passengers: [],
                        increments: 0,
                    }, */
                {
                    id: 6,
                    passenger_type: "Infant",
                    passenger_age_range: "Under 2",
                    passengers: [],
                    increments: 0,
                },
            ],
                flight_class: [
                {
                    id: 1,
                    class_name: "Economy",
                },
                /*          {
                             id: 2,
                             class_name: 'Premium Economy',

                         }, */
                {
                    id: 3,
                    class_name: "Business",
                },
                {
                    id: 4,
                    class_name: "First Class",
                },
                /*        {
                           id: 5,
                           class_name: 'Several Class',

                       }, */
            ],
                flight_type: [

{
    id: 1,
    type_name: "One Way",
},
{
    id: 2,
    type_name: "Roundtrip",
} /* ,
{
    id: 3,
    type_name: 'Multiple Destinations',

} */,
],
selected_flight_class: 1,
            selected_flight_type: 1,
                input_elements_test: null,
                first_date: '',
                second_date: '',
                search_keyword: '',
                selected_language : '',
                country: [],
                nationality: '',
                //cityCode: '',
                max_nr_of_rooms: 5,
                passenger_current_index: 1,
                room_current_index: 1,
                selected_result_object: null,
                hotelCodes: [],
                initial_autocomplete_value: false,
                paxRooms: [{ Adults: 2, Children: 0, ChildrenAges: [] }], // Initialize with default values
                unique_id: "package_search",
    
                passengers_to_display: '',
                passenger_types: [
                    {
                        id: 1,
                        type: 'Adult',
                        description: '18+',
    
                    },
                    {
                        id: 2,
                        type: 'Child',
                        description: 'under 12',
    
                    }
                ],
                rooms: [{
                    id: 1,
    
                    passengers: [
                        {
                            id: 1,
                            passenger_type_id: 1,
                            passenger_age: 0,
    
    
    
                        },
                        {
                            id: 1,
                            passenger_type_id: 1,
                            passenger_age: 0,
    
    
    
                        }
                    ],
    
                }],
                submitted: false,
            };
        },
    
        validations: {
            nationality: { required },
            search_keyword: { required },
    
        },
    
        computed: {
            PaxRooms() {
          // Calculate PaxRooms based on the rooms data
          return this.rooms.map(room => {
            const adults = room.passengers.filter(p => p.passenger_type_id === 1).length;
            const children = room.passengers.filter(p => p.passenger_type_id === 2).length;
            const childrenAges = room.passengers
              .filter(p => p.passenger_type_id === 2)
              .map(p => p.passenger_age);
    
            return {
              Adults: adults,
              Children: children,
              ChildrenAges: childrenAges,
            };
          });
        },
        
      },
      created() {
    this.checkIfMobile();
    window.addEventListener('resize', this.checkIfMobile); // Re-check on window resize
  },
    
        methods: {
            checkIfMobile() {
      // You can adjust the width threshold according to your mobile definition
      this.isMobile = window.innerWidth <= 768;
    },
            show_modal: function(elem) {
  document.querySelector(elem).classList.add("active");
  document.querySelector('.booking_modal_after').classList.add("active");
},

 remove_modal: function() {
  event.stopPropagation();
  let modal = document.querySelectorAll(".booking_modal");
  let after = document.querySelectorAll(".booking_modal_after");
  modal.forEach(element => {
    element.classList.remove("active");
  });
  after.forEach(after => {
    after.classList.remove("active");
  });
},
            swapDirections: function () {
        // Swap the keywords
        const tempKeyword = this.departure_search_keyword;
        this.departure_search_keyword = this.destination_search_keyword;
        this.destination_search_keyword = tempKeyword;

        // Swap the selected result objects
        const tempResultObject = this.selected_departure_result_object;
        this.selected_departure_result_object = this.selected_destination_result_object;
        this.selected_destination_result_object = tempResultObject;

        // Update the autocomplete components with the new values
        this.$refs.autocomplete_1.setInitialValue(this.departure_search_keyword);
        this.$refs.autocomplete_2.setInitialValue(this.destination_search_keyword);
    },
            handle_first_flight_date(event) {
           
     
           this.first_date = event;
       },
       handle_second_flight_date(event) {
           this.second_date = event;
       },
       select_departure_result: function (data) {
           this.departure_search_keyword = data.Name;
           this.selected_departure_result_object = data;
       },
       select_destination_result: function (data) {
           this.destination_search_keyword = data.Name;
           this.selected_destination_result_object = data;
       },
            activateAutocomplete: function(autocomplete_index)
            {
                let autocomplete_name = 'autocomplete_' + autocomplete_index;
                this.$refs[autocomplete_name].activateAutocomplete();
    
    
            },
            clearLocationInput: function () {
                this.departure_search_keyword = '';
            this.selected_departure_result_object = null;
            this.destination_search_keyword = '';
            this.selected_destination_result_object = null;
            },
            passengers_breakup: function () {
                let adults = 0;
                let children = 0;
    
                let rooms = this.rooms;
    
                for (let c = 0; c < rooms.length; c++) {
                    for (let p = 0; p < rooms[c]['passengers'].length; p++) {
                        if (rooms[c]['passengers'][p]['passenger_type_id'] == 1) {
                            adults += 1;
                        } else if (rooms[c]['passengers'][p]['passenger_type_id'] == 2) {
                            children += 1;
                        }
                    }
                }
                if (children == 0) {
                    this.passengers_to_display = `${adults} Adults`
                } else {
                    this.passengers_to_display = `${adults} Adults, ${children} Child`
                }
    
    
            },
            search: async function () {
                this.submitted = true;
    
                let d = new FormData();
        d.append('city_id', this.selected_result_object.city_id)
        d.append('Name', this.selected_result_object.Name)
        d.append('check_in_date', this.first_date)
        d.append('check_out_date', this.second_date)
        d.append('rooms', JSON.stringify(this.rooms))
        d.append('paxRooms', JSON.stringify(this.paxRooms));
        d.append('hotelCodes', JSON.stringify(this.hotelCodes));
        d.append('nationality', this.nationality)
    
        // Conditionally append trip_id if it has a value
        if (this.trip_id !== null && this.trip_id !== undefined) {
            d.append('trip_id', this.trip_id);
        }
    
    
        try {
            let resp = await axios.post('/hotels/prepare_search', d);
               // console.log(resp.data );
    
            let route = "/hotels/search?key=" + resp.data + '&name=' + encodeURIComponent(this.selected_result_object.Name) + '&city_id=' + this.selected_result_object.city_id + '&check_in=' + encodeURIComponent(this.first_date) + '&check_out=' + encodeURIComponent(this.second_date);
    
            if (this.trip_id !== null && this.trip_id !== undefined) {
                route += "&trip_id=" + this.trip_id;
            }
    
            // Prepend selected_language to the route if it's not 'en'
            if (this.selected_language !== 'en') {
                route = `/${this.selected_language}${route}`;
            }
    
            window.location = route;
        } catch (error) {
            console.error('Error preparing search:', error);
        }
    },
            handle_first_date(event) {
                this.first_date = event
                //console.log('first_date ch:', this.first_date);
            },
            handle_second_date(event) {
                this.second_date = event
               // console.log('second_date ch:', this.second_date);
    
            },
            removeRoom(room) {
                this.rooms = this.rooms.filter(r => {
                    return !(room.id == r.id)
                })
                this.passengers_breakup();
    
            },
            change_child_age: function (child, new_value) {
                if (new_value > -1 && new_value < 18) {
                    child.passenger_age = new_value
                }
            },
            addFlightPassenger: function (passenger_type) {
            if (this.passengersCount() > 8) {
                return;
            }
            this.flight_passengers.forEach((p) => {
                if (p.id === passenger_type.id) {
                    p.increments += 1;
                    p.passengers.push({
                        id: p.increments,
                    });
                }
            });
        },
            addPassenger(passenger_type_id, room_id, age = 0) {
                this.rooms.forEach(r => {
                    if (r.id == room_id) {
                        if (r.passengers.length > 4) {
                            return;
                        }
                        this.passenger_current_index += 1;
                        r.passengers.push({
                            passenger_type_id,
                            passenger_age: age,
                            id: this.passenger_current_index,
    
                        })
                        this.passengers_breakup();
                    }
                })
            },
            checkForInit() {
                let select_box = document.querySelectorAll(".not_init_yet");
                for (let i = 0; i < select_box.length; i++) {
                    NiceSelect.bind(select_box[i]);
    
                }
                for (let r = 0; r < this.rooms.length; r++) {
    
                    for (let p = 0; p < this.rooms[r].passengers.length; p++) {
                        if (this.rooms[r].passengers[p].nice_select_init == false) {
                            this.rooms[r].passengers[p].nice_select_init = true
                        }
                    }
                }
    
    
            },
            removePassenger(passenger_type_id, room, passenger_id, age = 0) {
                if (room.passengers.length <= 1) return
                let passenger_deleted = false;
                for (let c = 0; c < room.passengers.length; c++) {
                    if (room.passengers[c].passenger_type_id == passenger_type_id && room.passengers[c].passenger_age == age) {
                        room.passengers.splice(c, 1);
                        this.passengers_breakup();
                        break;
                    }
                }
            },
            addRoom() {
                if (this.rooms.length > this.max_nr_of_rooms) {
                    return;
                }
                this.room_current_index += 1;
                this.rooms.push({
                    id: this.room_current_index,
    
                    passengers: [
                        {
                            id: 1,
                            passenger_type_id: 1,
                            passenger_age: 0,
    
    
    
                        },
                        {
                            id: 1,
                            passenger_type_id: 1,
                            passenger_age: 0,
    
    
                        },
                    ],
    
                })
                this.passengers_breakup();
    
            },
            getNumberOfPassengers(room, passenger_type_id) {
                let count = 0;
                let pax = room.passengers.forEach(p => {
                    if (p.passenger_type_id == passenger_type_id) {
                        count += 1;
    
                    }
                })
                return count;
            },
            roomChildPassengers: function (room) {
                let childs = room.passengers.filter(c => {
                    return c.passenger_type_id == 2;
                })
                return childs;
    
            },
    
            select_result: function (data) {
                this.search_keyword = data.Name;
                this.selected_result_object = data;
            },
    
        },
        props: {
            initial_search_data: null,
            has_initial_value: false,
            type: '',
            keyword: '',
            current_view_style: null,
            search_data: null,
            top: null,
            trip_id: null,
        },
        watch: {
            hotelCodes: {
        handler(newHotelCodes, oldHotelCodes) {
          // This function will be called whenever hotelCodes changes.
          // You can perform actions or computations based on the new value (newHotelCodes) or the old value (oldHotelCodes).
          //console.log('hotelCodes changed:', newHotelCodes);
          
          // Example: You can trigger a function when hotelCodes changes.
          // this.someFunction(newHotelCodes);
        },
        deep: true, // Watch for changes in nested properties of hotelCodes
      },
    
      rooms: {
          handler(newRooms) {
            // Recalculate paxRooms based on the newRooms data
            this.paxRooms = newRooms.map(room => {
              const adults = room.passengers.filter(p => p.passenger_type_id === 1).length;
              const children = room.passengers.filter(p => p.passenger_type_id === 2).length;
              const childrenAges = room.passengers
                .filter(p => p.passenger_type_id === 2)
                .map(p => p.passenger_age);
    
              return {
                Adults: adults,
                Children: children,
                ChildrenAges: childrenAges,
              };
            });
          },
          deep: true, // Watch for nested changes in rooms data
        },
      },
    
    
        async mounted() {
           // console.log(this.first_date);
           // console.log(this.second_date);
    
            this.selected_language = language_app.selected_language;
    
            fetch('/country.json')
          .then(response => response.json())
          .then(data => {
            // Set the countries data
            this.country = data;
          })
          .catch(error => {
            console.error('Error loading countries:', error);
          });
    
    
            if (this.trip_id) {
        this.trip_id = this.trip_id;
        }
    
     
            // Check if initial_search_data is defined and contains hotelCodes
        if (this.initial_search_data && this.initial_search_data.hotelCodes) {
          // Assign the hotelCodes to localHotelCodes
          this.HotelCodes = this.initial_search_data.hotelCodes;
        }
              
        let input__date = document.querySelectorAll(".drp-selected-start, .drp-selected-end");
       // console.log('Input Date Elements:', input__date);
    
            if (this.initial_search_data) {
              //  console.log('Initial Search Data:', this.initial_search_data);
              let departure_date = null;
        let return_date = null;
        if (this.search_data && !this.search_data.trip) {
            this.selected_flight_class = parseInt(
                this.search_data.flight_class
            );
            departure_date = this.search_data.departure_date;
            return_date = this.search_data.return_date;

            this.first_date = departure_date;
            this.second_date = return_date;
            this.selected_flight_type = parseInt(this.search_data.flight_type);
            this.flight_passengers = JSON.parse(
                this.search_data.flight_passengers
            );
            let resp = await axios.post("/flight/suggest_airport_by_id", {
                Code: this.search_data.departure_airport_id,
            });
            this.selected_departure_result_object = resp.data[0];
            let resp2 = await axios.post("/flight/suggest_airport_by_id", {
                Code: this.search_data.destination_airport_id,
            });
            this.selected_destination_result_object = resp2.data[0];

            this.initial_autocomplete_value = resp.data[0].Name;
            this.initial_autocomplete_value2 = resp2.data[0].Name;

            this.departure_search_keyword = resp.data[0].Name;

            this.destination_search_keyword = resp2.data[0].Name;
                this.rooms = JSON.parse(this.initial_search_data.rooms);
                this.paxRooms = JSON.parse(this.initial_search_data.paxRooms); // Use paxRooms instead of rooms
               // console.log('Rooms:', this.rooms);
           // console.log('PaxRooms:', this.paxRooms);
        } else {
            this.addFlightPassenger({ id: 1 });
        }
           if (this.$refs.date_picker_3) {
          this.$refs.date_picker_3.attach_date_picker(
            input__date,
            departure_date,
            return_date,
            this.initial_search_data.check_in_date,
            this.initial_search_data.check_out_date
          );
        }

               /*  let resp3 = await axios.post('/hotel/suggest_location_by_city_id', { city_id: this.initial_search_data.city_id })
                this.search_keyword = resp3.data[0].Name
                this.selected_result_object = resp3.data[0];
    
                this.initial_autocomplete_value = resp3.data[0].Name */
                this.$forceUpdate(); // Force an update
            }
    
            else {
                let first_date = moment().add(7, 'days').format('YYYY-MM-DD')
                let second_date = moment().add(8, 'days').format('YYYY-MM-DD')
                this.$refs.date_picker_3?.attach_date_picker(input__date, first_date, second_date);
            }
    
            this.passengers_breakup();

        },
        beforeDestroy() {
    window.removeEventListener('resize', this.checkIfMobile); // Clean up the event listener
  },
        
    };
    </script>
    
    <style>
    
    .d-wf0o1 {
    background: rgb(245, 245, 245);
    border-radius: 12px;
    border: 0px solid rgb(245, 245, 245);
    display: flex;
    gap: 4px;
    overflow: hidden;
    padding: 4px;
    width: fit-content;
}
.d-qwct0k {
    -webkit-box-align: center;
    align-items: center;
    cursor: pointer;
    margin: auto 0px;
    padding: 4px 16px;
    text-align: center;
    -webkit-tap-highlight-color: transparent;
    background-color: rgb(255, 255, 255);
    border-radius: 8px;
    border: 0px solid rgb(186, 186, 192);
    box-shadow: rgba(0, 0, 0, 0.04) 0px 4px 8px, rgba(0, 0, 0, 0.06) 0px 0px 2px, rgba(0, 0, 0, 0.04) 0px 0px 1px;
    color: rgb(242, 0, 125);
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: 700;
}

.d-pdx4vb {
    -webkit-box-align: center;
    align-items: center;
    cursor: pointer;
    margin: auto 0px;
    padding: 4px 16px;
    text-align: center;
    -webkit-tap-highlight-color: transparent;
    background-color: rgb(245, 245, 245);
    border-radius: 8px;
    border: 0px solid rgb(186, 186, 192);
    box-shadow: unset;
    color: rgb(60, 60, 60);
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: 400;
}
.hide_me {
            display: none !important;
        }

    </style>
    