<template>
      <vue-country-dropdown
        @onSelect="onSelect"
        :preferredCountries="['SE', 'US', 'GB']"
        :disabledFetchingCountry="true"
        :defaultCountryByAreaCode="46"
        :immediateCallSelectEvent="true"
        :enabledFlags="true"
        :enabledCountryCode="false"
        :showNameInput="true"
        :showNotSelectedOption="false"
        :notSelectedOptionText="'Not Selected'"
    >
    </vue-country-dropdown>
</template>

<script>
import VueCountryDropdown from 'vue-country-dropdown'

export default {
    components: {
        VueCountryDropdown
    },
    data: function () {
        return {
            country: '',
        };
    },

    props: {
        default_country: null,
    },

    methods: {
     onSelect({name, iso2, dialCode}) {
   
       this.$emit("on_select", name)
     },

     mounted: function()
     {
        if (this.default_country !== null)
        {
             this.$emit("on_select", this.default_country.country)
        }
     }
  },
};
</script>

<style scoped>
</style>