<template>


    <div v-show="isVisible" class="autocomplete-items">
        <div v-for="(r, index) in results" v-on:click="selectResult(r)">
            <span class="all_airports">
                <i class="fas fa-map-marker-alt me-2"></i> {{ r.name }}, {{ r.country.name }}
            </span>

        </div>

    </div><!--  -->



</template>

<script>
export default {
    data: function () {
        return {
            results: [],
            cancelSource: null,
            isVisible: false,
        };
    },
    props: {
        keyword: "",

    },
    watch: {
        keyword: async function (newKeyword, oldKeyword) {
            if (newKeyword.length < 1) {
                this.isVisible = false;
                return;
            }


            await this.performAutocomplete(newKeyword);
        },
    },

    methods: {
        performAutocomplete: async function (keyword) {

            this.cancelSource = axios.CancelToken.source();
            const resp = await axios
                .get("/suggest_locations?keyword=" + keyword, {
                    cancelToken: this.cancelSource.token,
                })
                .catch(function (thrown) {
                    if (axios.isCancel(thrown)) {
                    } else {
                        // handle error
                    }
                });
            this.results = [];
            this.results.push(...resp.data);
            if (this.results.length > 0) {
                this.isVisible = true;
            }
        },

        cancelRequest: function () {
            this.cancelSource.cancel();
        },

        selectResult: function (result) {
            this.$emit("autocomplete_result_selected", result);
            this.isVisible = false;
        },
    },
    mounted() {
        if (this.keyword !== "") {
            this.performAutocomplete(this.keyword);
        }
    },
};
</script>

<style scoped>
.container {
    background-color: white;
    border: #00000028 solid 1px;
    box-shadow: 0px 3px 6px #00000028;
}
</style>