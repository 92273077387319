<template>
    <div class="skeleton_view_box">
        <Skeleton 
            :count="count"
            :duration="duration" 
            :width="width" 
            :height="height" 
            :circle="circle" 
            :loading="loading" 
        />
    </div>
</template>

<script>
import { Skeleton } from 'vue-loading-skeleton';

export default {
    components: { 
        Skeleton,
    },

    data: function () {
        return {
        };
    },

    props: {
        count: {
            type: Number,
            default: 1
        },
        duration:  {
            type: Number,
            default: 1.5
        },
        width:  {
            type: String,
            default: "100%"
        },
        height:  {
            type: String,
            default: "30px"
        },
        circle:  {
            type: Boolean,
            default: false
        },
        loading:  {
            type: Boolean | undefined,
            default: undefined
        },
    },
};
</script>

<style scoped>
.skeleton_view_box span {
    margin-top: 5px;
}
</style>