import { render, staticRenderFns } from "./SkeletonFlightItem.vue?vue&type=template&id=36f0e0ed&scoped=true"
import script from "./SkeletonFlightItem.vue?vue&type=script&lang=js"
export * from "./SkeletonFlightItem.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36f0e0ed",
  null
  
)

export default component.exports