<template>
    <div class="trip_main_box_wrapper pad__x skeletion_searching_box">
        <div class="trip_box_header">
            <Skeleton :count="1" height="25px" width="20%"/>
        </div>
        <div>
            <h6 class="trip_box_content_txt mb-2"><Skeleton :count="1" height="25px" width="30%"/></h6>
            <div class="trip_box_content_time">
                <div class="trip_box_time_wrapper">
                    <div class="trip_box_time_item">
                        <span><Skeleton :count="1" height="25px" width="50%"/></span>
                    </div>

                    <span><Skeleton :count="1" height="25px" width="50%"/></span>
                </div>
            </div>
        </div>
        <div class="trip_box_footer">
            <div class="trip_box_footer_bar">
                <span><Skeleton :count="1" height="25px" width="80%"/></span>
            </div>
            <div class="trip_box_footer_bar_item trip_box_responsive_order" style="justify-content: start !important;" id="accordionOne">
                <span><Skeleton :count="1" height="25px" width="90%"/></span>
                </div>
        </div>
        <div class="d-flex align-items-center justify-content-between trip_submit_wrapper">
            <span><Skeleton :count="1" height="25px" width="60%"/></span>
        </div>
    </div>
</template>

<script>
import { Skeleton } from 'vue-loading-skeleton';

export default {
    components: { 
        Skeleton,
    },
    data: function () {
        return {
        };
    },
};
</script>

<style scoped>
</style>