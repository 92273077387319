<template>

    <div v-if="current_view_style == 1" class="flight_tab_content pt-3 pb-1">
        <div class="booking_modal_after" v-on:click="remove_modal()"></div>
    
      <div class="hide_me box_1">
        <div class="bg_silver rounded_10 d-flex align-items-center">
            <div class="d-wf0o1">
  <select class="nice_select float-none w-100" v-model="selected_flight_type" aria-label="Select flight type">
    <option v-for="flighttype in flight_type" :key="flighttype.id" :value="flighttype.id">
      {{ flighttype.type_name }}
    </option>
  </select>
</div>

<div class="d-wf0o1 me-4">
  <select class="nice_select float-none w-100" v-model="selected_flight_class" aria-label="Select flight class">
    <option v-for="(flightclass, index) in flight_class" :key="flightclass.id" :value="flightclass.id">
      {{ flightclass.class_name }}
    </option>
  </select>
</div>

          
        </div>
      </div>
                    <div class="box_1">
                      <div class="bg_silver rounded_10 d-flex align-items-center">
                        <label class="w-100 position-relative">
                            <span class="d-block fs_14">Departure</span>
                            <input v-on:input="departure_search_keyword = $event.target.value" 
                                   v-model="departure_search_keyword" 
                                   @focus="activateAutocomplete(1)" 
                                   type="text" id="inp_flight_1" 
                                   placeholder="City or airport" />
                                   <span class="clear_input_ticket" @click="clearLocationInput()"></span>
                            <div v-if="selected_departure_result_object_valid == false" class="invalid-feedback">
                                Location is required
                            </div>
                            <AutocompleteComponent ref="autocomplete_1" 
                                                   :has_initial_value="initial_autocomplete_value"
                                                   @autocomplete_result_selected="select_departure_result" 
                                                   :type="'airport'" 
                                                   :keyword="departure_search_keyword">
                            </AutocompleteComponent>
                        </label>
                        <button type="button" @click="swapDirections">
                            <img width="50%" src="/img/direction-icon.svg" alt="" />
                        </button>
                        <label class="w-100 position-relative">
                            <span class="d-block fs_14">Destination</span>
                            <input v-on:input="destination_search_keyword = $event.target.value"
                                   @focus="activateAutocomplete(2)" 
                                   v-model="destination_search_keyword" 
                                   type="text" id="inp_flight_2" 
                                   placeholder="City or airport" />
                            <span class="clear_input_ticket" @click="clearLocationInput2()">
                                <img src="/img/clear_cross.svg" alt="Clear icon" />
                            </span>
                            <div v-if="selected_destination_result_object_valid == false" class="invalid-feedback">
                                Location is required
                            </div>
                            <AutocompleteComponent ref="autocomplete_2" 
                                                   :has_initial_value="initial_autocomplete_value"
                                                   @autocomplete_result_selected="select_destination_result" 
                                                   :type="'airport'" 
                                                   :keyword="destination_search_keyword">
                            </AutocompleteComponent>    
                        </label>
                    </div>
                    </div>
                    <div class="box_2">
                      <div class="bg_silver rounded_10 d-flex position-relative">
                        <label for="date_range2" v-on:click="show_modal('#date_range2')">
                            <span class="d-block fs_14">Depart</span>
                            <input autocomplete="off" :value="first_date" type="button" id="first_date" class="left-aligned-text drp-selected-start" readonly="">
                        </label>
                        <span v-if="selected_flight_type !== 1" class="line"></span>
                        <label v-if="selected_flight_type !== 1" for="date_range2" v-on:click="show_modal('#date_range2')">
                            <span class="d-block fs_14">Return</span>
                            <input :class="{'has_error' : invalid_return_date}" autocomplete="off" :value="second_date" type="button" id="second_date" class="left-aligned-text drp-selected-end" readonly="">
                        </label>
                        <input type="text" class="date_range">   
                    </div>
                    <div id="date_range2" :class="{'booking_modal': !isMobile}" style="position:absolute;width: max-content; left: 350px;z-index:100">
                      <DatePicker :unique_id="unique_id" @first_date_changed="handle_first_flight_date" :range="selected_flight_type !== 1"
                                  @second_date_changed="handle_second_flight_date" ref="date_picker_2">
                      </DatePicker>
                  </div>
                    </div>
                    <div class="box_3">
                      <div class="bg_silver rounded_10 d-flex position-relative"
                    v-on:click="show_modal('#booking_modal_2')">
                        <label>
                            <span class="d-block fs_14">Passengers</span>
                            <input type="button" class="left-aligned-text c_pointer" readonly ref="input_box1" :value="passengers_to_display" @focus="toggle_passengers_panel" />
                        </label>
                        <div id="booking_modal_2" class="booking_modal">
                            <div class="booking_modal_sm position-absolute w-100 h-100 bg-red" v-on:click="remove_modal()"></div>
                            <div class="shadow booking_box">
                                <div v-for="passenger in flight_passengers" class="d-flex justify-content-between mb-3">
                                    <div class="me-3">
                                        <p class="mb-0 fw_500">{{ passenger.passenger_type }}</p>
                                        <p class="mb-0 fs_12">{{ passenger.passenger_age_range }}</p>
                                    </div>
                                    <div class="wrap_inc_dec">
                                        <button class="decrement" v-on:click="removePassenger(passenger.id)">-</button>
                                        <input type="number" readonly class="numberInput" :value="passenger.passengers.length" />
                                        <button class="increment" v-on:click="addPassenger(passenger)">+</button>
                                    </div>
                                </div>
                                <hr>
                                <h5 class="fw-bold mb-3 fs_18">Flight Type</h5>
                                
                                <div class="booking_class_btn">
          <button 
            v-for="(flighttype, index) in flight_type" 
            :key="flighttype.id"
            :class="{ 'active': selected_flight_type === flighttype.id }"
            @click="selected_flight_type = flighttype.id"
          >
            {{ flighttype.type_name }}
          </button>
        </div>
        <hr>
                                <h5 class="fw-bold mb-3 fs_18">Flight Class</h5>
                                <div class="booking_class_btn">
          <button 
            v-for="(flightclass, index) in flight_class" 
            :key="flightclass.id"
            :class="{ 'active': selected_flight_class === flightclass.id }"
            @click="selected_flight_class = flightclass.id"
          >
            {{ flightclass.class_name }}
          </button>
        </div>
                                <button v-on:click="remove_modal()" class="ms-0 mt-2 txt_pink fw-bold btn_reset fs_14 c_pointer1">
                                                Apply
                                            </button>
                            </div>
                        </div>
                    </div>
                    </div>
                    <button class="d-flex align-items-center justify-content-center" @click="search" type="submit">
                      <img width="24" src="/img/search-icon.svg" alt="" />
                      <span class="d-md-none d-inline-block text-white fw-semibold fs_14">Find</span>
                  </button>
                  </div>
    </template>
    
    
    <script>
    import DatePicker from "./DatePicker-new.vue";
    import AutocompleteComponent from "./AutocompleteComponent.vue";
    export default {
        components: { AutocompleteComponent, DatePicker },
        data: function () {
            return {
                passengers_to_display: '',
                isMobile: false,
                selected_language : '',
                invalid_return_date: false,
                holidayDatepickerVisible: false,
                unique_id: "flight_search",
                first_date: "",
                second_date: "",
                departure_search_keyword: "",
                destination_search_keyword: "",
                selected_departure_result_object: {},
                selected_destination_result_object: {},
                my_nice_selects: [],
                initial_autocomplete_value: false,
                initial_autocomplete_value2: false,
                flights_loading: true,
                selected_departure_result_object_valid: true,
                selected_destination_result_object_valid: true,
                first_date_valid: true,
                second_date_valid: true,
    
    
                passenger_panel_visible: false,
    
                flight_passengers: [
                    {
                        id: 1,
                        passenger_type: "Adult",
                        passenger_age_range: "18+",
                        passengers: [],
                        increments: 0,
                    },
                    /*         {
                                id: 2,
                                passenger_type: 'Student',
                                passenger_age_range: '18+',
                                passengers: [],
                                increments: 0,
                            },
                            {
                                id: 3,
                                passenger_type: 'Young People',
                                passenger_age_range: '12 - 17',
                                passengers: [],
                                increments: 0,
                            }, */
                    {
                        id: 4,
                        passenger_type: "Children",
                        passenger_age_range: "2 - 11",
                        passengers: [],
                        increments: 0,
                    },
                    /*     {
                            id: 5,
                            passenger_type: 'Small children in their own seat',
                            passenger_age_range: 'Under 2',
                            passengers: [],
                            increments: 0,
                        }, */
                    {
                        id: 6,
                        passenger_type: "Infant",
                        passenger_age_range: "Under 2",
                        passengers: [],
                        increments: 0,
                    },
                ],
    
                flight_class: [
                    {
                        id: 1,
                        class_name: "Economy",
                    },
                    /*          {
                                 id: 2,
                                 class_name: 'Premium Economy',
    
                             }, */
                    {
                        id: 3,
                        class_name: "Business",
                    },
                    {
                        id: 4,
                        class_name: "First Class",
                    },
                    /*        {
                               id: 5,
                               class_name: 'Several Class',
    
                           }, */
                ],
    
                flight_type: [
    
                    {
                        id: 1,
                        type_name: "One Way",
                    },
                    {
                        id: 2,
                        type_name: "Roundtrip",
                    } /* ,
                    {
                        id: 3,
                        type_name: 'Multiple Destinations',
    
                    } */,
                ],
    
                selected_flight_class: 1,
                selected_flight_type: 1,
            };
        },
        props: {
        has_initial_value: false,
        type: '',
        keyword: '',
            top: null,
            visible: false,
            current_view_style: null,
            search_data: null,
        },
        created() {
    this.checkIfMobile();
    window.addEventListener('resize', this.checkIfMobile); // Re-check on window resize
  },
    
        methods: {
            passengers_breakup() {
                    if (!this.flight_passengers) {
                        return '';
                    }
                    let pax = this.flight_passengers;
                    let adults = 0;
                    let children = 0;
                    let infants = 0;

                    for (let i = 0; i < pax.length; i++) {
                        if (pax[i].id == 1) {
                            adults = pax[i].passengers.length;
                        }
                        if (pax[i].id == 4) {
                            children = pax[i].passengers.length;
                        }
                        if (pax[i].id == 6) {
                            infants = pax[i].passengers.length;
                        }
                    }
                    let total = adults + children + infants
                    let type_of_flight = ''
                    if (this.flight_type == 1) {
                        type_of_flight = "One Way"
                    } else if (this.flight_type == 2) {
                        type_of_flight = "Roundtrip"
                    } else {
                        type_of_flight = "Multiple"
                    }
                    let passengers = ''

                    if (adults > 0) {
                        passengers += adults + " Adult(s)"
                    }
                    if (children > 0) {
                        passengers += children + " Child,"
                    }
                    if (infants > 0) {
                        passengers += infants + ", Infant,"
                    }
                    this.passengers_to_display = `${passengers}`

                   // return `${type_of_flight}, ${passengers}`

                },
            checkIfMobile() {
      // You can adjust the width threshold according to your mobile definition
      this.isMobile = window.innerWidth <= 768;
    },
            show_modal: function(elem) {
      document.querySelector(elem).classList.add("active");
      document.querySelector('.booking_modal_after').classList.add("active");
    },
    
     remove_modal: function() {
      event.stopPropagation();
      let modal = document.querySelectorAll(".booking_modal");
      let after = document.querySelectorAll(".booking_modal_after");
      modal.forEach(element => {
        element.classList.remove("active");
      });
      after.forEach(after => {
        after.classList.remove("active");
      });
    },
            swapDirections: function () {
            // Swap the keywords
            const tempKeyword = this.departure_search_keyword;
            this.departure_search_keyword = this.destination_search_keyword;
            this.destination_search_keyword = tempKeyword;
    
            // Swap the selected result objects
            const tempResultObject = this.selected_departure_result_object;
            this.selected_departure_result_object = this.selected_destination_result_object;
            this.selected_destination_result_object = tempResultObject;
    
            // Update the autocomplete components with the new values
            this.$refs.autocomplete_1.setInitialValue(this.departure_search_keyword);
            this.$refs.autocomplete_2.setInitialValue(this.destination_search_keyword);
        },
            activateAutocomplete: function (autocomplete_index) {
                let autocomplete_name = 'autocomplete_' + autocomplete_index;
                this.$refs[autocomplete_name].activateAutocomplete();
    
    
            },
    
            clearLocationInput1: function () {
                this.departure_search_keyword = '';
                this.selected_departure_result_object = null;
            },
    
            clearLocationInput2: function () {
                this.destination_search_keyword = '';
                this.selected_destination_result_object = null;
            },
    
            swap_directions: function () {
    
                let temp = this.selected_departure_result_object;
                this.selected_departure_result_object = this.selected_destination_result_object;
                this.select_destination_result = temp;
    
    
                let temp2 = this.departure_search_keyword;
                this.departure_search_keyword = this.destination_search_keyword;
                this.destination_search_keyword = temp2;
    
            },
            updateNiceSelects: function () {
                for (let i = 0; i < this.my_nice_selects.length; i++) {
                    this.my_nice_selects[i].update();
                }
         
            },
    
            hidePanel() {
                this.passenger_panel_visible = false;
            },
    
            checkForNiceSelect: function () {
                let x = $($(".flight_select_box")[0]).css("display");
             
            },
            search: async function (should_redirect = true) {
                let valid_input = true;
                let range = false;
    
                if (!(Object.keys(this.selected_departure_result_object).length > 0)) {
                    this.selected_departure_result_object_valid = false;
                    valid_input = false;
                 
                }
                else
                {
                    this.selected_departure_result_object_valid = true;
                }
    
                if (!(Object.keys(this.selected_destination_result_object).length > 0)) {
                    this.selected_destination_result_object_valid = false
                    valid_input = false;
     
    
                }
                else
                {
                    this.selected_destination_result_object_valid = true;
                }
    
                if (!valid_input)
                {
                    return;
                }
    
                if (this.second_date)
                {
                    range = true;
                }
    
                if (range)
                {
                    if (moment(this.second_date).diff(moment(this.first_date) , 'days') <= 0)
                    {
                        this.invalid_return_date = true;
                        return;
                    }
                    else
                    {
                        this.invalid_return_date = false;
                    }
                }
                
                let d = new FormData();
                d.append("departure_date", this.first_date);
                d.append("return_date", this.second_date);
                d.append(
                    "departure_airport_id",
                    this.selected_departure_result_object.iata
                );
                d.append(
                    "destination_airport_id",
                    this.selected_destination_result_object.iata
                );
                d.append("flight_class", this.selected_flight_class);
                d.append("flight_type", this.selected_flight_type);
                d.append(
                    "flight_passengers",
                    JSON.stringify(this.flight_passengers)
                );
    
           
    
                let resp = await axios.post("/flight/prepare_flight_search", d);
               
                if (should_redirect) {
                    let route = "/flights/search?key=" + resp.data + '&from=' + encodeURIComponent(this.selected_departure_result_object.iata) + '&to=' + this.selected_destination_result_object.iata + '&departure=' + encodeURIComponent(this.first_date) + '&arrival=' + encodeURIComponent(this.second_date);
    // Prepend selected_language to the route if it's not 'en'
                    if (this.selected_language !== 'en') {
                route = `/${this.selected_language}${route}`;
            }
            window.location = route;
    
    
                } else {
                    this.$emit("new_search", {
                        departure_date: this.first_date,
                        return_date: this.second_date,
                        departure_airport_id:
                            this.selected_departure_result_object.iata,
                        destination_airport_id:
                            this.selected_destination_result_object.iata,
                        flight_class: this.selected_flight_class,
                        flight_type: this.selected_flight_type,
                        flight_passengers: JSON.stringify(this.flight_passengers),
                        search_key: resp.data,
                        trip: this.search_data.trip,
                    });
                    let route = "/flights/search?key=" + resp.data + '&from=' + encodeURIComponent(this.selected_departure_result_object.iata) + '&to=' + this.selected_destination_result_object.iata + '&departure=' + encodeURIComponent(this.first_date) + '&arrival=' + encodeURIComponent(this.second_date);
    // Prepend selected_language to the route if it's not 'en'
                    if (this.selected_language !== 'en') {
                route = `/${this.selected_language}${route}`;
            }
            window.location = route;
    
                }
            },
            handle_first_flight_date(event) {
               
         
                this.first_date = event;
            },
            handle_second_flight_date(event) {
                this.second_date = event;
            },
            select_departure_result: function (data) {
                this.departure_search_keyword = data.name;
                this.selected_departure_result_object = data;
            },
            select_destination_result: function (data) {
                this.destination_search_keyword = data.name;
                this.selected_destination_result_object = data;
            },
            addPassenger: function (passenger_type) {
                if (this.passengersCount() > 8) {
                    return;
                }
                this.flight_passengers.forEach((p) => {
                    if (p.id === passenger_type.id) {
                        p.increments += 1;
                        p.passengers.push({
                            id: p.increments,
                        });
                this.passengers_breakup();

                    }
                });
            },
            passengersCount: function () {
                let total = 0;
                this.flight_passengers.forEach((p) => {
                    total += p.passengers.length;
                });
    
                return total;
            },
            removePassenger: function (passenger_type_id, passenger = null) {
                this.flight_passengers.forEach((pt) => {
                    if (pt.id === passenger_type_id) {
                        if (passenger_type_id == 1 && pt.passengers.length == 1) {
                            return;
                        }
                        if (passenger) {
                            pt.forEach((p, index) => {
                                if (p.id === passenger.id) {
                                    p.splice(index, 1);
                                    this.passengers_breakup();

                                }
                            });
                        } else {
                            pt.passengers.splice(0, 1);
                            this.passengers_breakup();

                        }
                    }
                });
            },
            toggle_passengers_panel: function () {
                this.passenger_panel_visible = !this.passenger_panel_visible;
            },
        },
        async mounted() {
            this.selected_language = language_app.selected_language;
    
            let departure_date = null;
            let return_date = null;
            if (this.search_data) {
                this.selected_flight_class = parseInt(
                    this.search_data.flight_class
                );
                departure_date = this.search_data.departure_date;
                return_date = this.search_data.return_date;
    
                this.first_date = departure_date;
                this.second_date = return_date;
                this.selected_flight_type = parseInt(this.search_data.flight_type);
                this.flight_passengers = JSON.parse(
                    this.search_data.flight_passengers
                );
    
                let resp = await axios.post("/flight/suggest_airport_by_id", {
                    Code: this.search_data.departure_airport_id,
                });
                this.selected_departure_result_object = resp.data[0];
                let resp2 = await axios.post("/flight/suggest_airport_by_id", {
                    Code: this.search_data.destination_airport_id,
                });
                this.selected_destination_result_object = resp2.data[0];
    
                this.initial_autocomplete_value = resp.data[0].name;
                this.initial_autocomplete_value2 = resp2.data[0].name;
    
                this.departure_search_keyword = resp.data[0].name;
    
                this.destination_search_keyword = resp2.data[0].name;
                if (this.$refs.date_picker2) {
      this.$refs.date_picker2.attach_date_picker(
        input_dates,
        this.search_data.departure_date,
        this.search_data.return_date
      );
    }
    this.$forceUpdate(); // Force an update

            } else {
                this.addPassenger({ id: 1 });
                let input_dates = document.querySelectorAll(".drp-selected-start, .drp-selected-end");

                this.$refs.date_picker_2?.attach_date_picker(
                input_dates,
                departure_date,
                return_date
            );
            }

    
            this.passengers_breakup();
    
        },
        beforeDestroy() {
    window.removeEventListener('resize', this.checkIfMobile); // Clean up the event listener
  },
    
        watch:
        {
            selected_flight_type: function (val) {
                if (val !== 1) {
    
                    this.$nextTick(() => {
                        let input_dates = document.querySelectorAll(".drp-selected-start, .drp-selected-end");
                        this.$refs.date_picker_2?.attach_date_picker(
                            input_dates,
                            null,
                            null
                        );
                    })
    
                }
            }
        }
    
    };
    </script>
    
    <style>
    .has_error
    {
        border-color: red !important;
        color: rgba(212, 22, 22, 0.575) !important;
    
    
    }
    .left-aligned-text {
        text-align: left;
        padding-left: 0px; /* Adjust padding as needed */
    }
    
    
    .invalid-feedback
    {
        display: block !important;
    }
    
    
    
    .d-wf0o1 {
        background: rgb(245, 245, 245);
        border-radius: 12px;
        border: 0px solid rgb(245, 245, 245);
        display: flex;
        gap: 4px;
        overflow: hidden;
        padding: 4px;
        width: fit-content;
    }
    .d-qwct0k {
        -webkit-box-align: center;
        align-items: center;
        cursor: pointer;
        margin: auto 0px;
        padding: 4px 16px;
        text-align: center;
        -webkit-tap-highlight-color: transparent;
        background-color: rgb(255, 255, 255);
        border-radius: 8px;
        border: 0px solid rgb(186, 186, 192);
        box-shadow: rgba(0, 0, 0, 0.04) 0px 4px 8px, rgba(0, 0, 0, 0.06) 0px 0px 2px, rgba(0, 0, 0, 0.04) 0px 0px 1px;
        color: rgb(242, 0, 125);
        font-family: "Open Sans", sans-serif;
        font-size: 14px;
        font-weight: 700;
    }
    
    .d-pdx4vb {
        -webkit-box-align: center;
        align-items: center;
        cursor: pointer;
        margin: auto 0px;
        padding: 4px 16px;
        text-align: center;
        -webkit-tap-highlight-color: transparent;
        background-color: rgb(245, 245, 245);
        border-radius: 8px;
        border: 0px solid rgb(186, 186, 192);
        box-shadow: unset;
        color: rgb(60, 60, 60);
        font-family: "Open Sans", sans-serif;
        font-size: 14px;
        font-weight: 400;
    }
    
    @media (max-width: 576px) {
    
    .booking_modal .booking_box {
        margin-left: -70px !important;
    }
    }
    .hide_me {
            display: none !important;
        }
    </style>
    