<template>
      <!-- Sliders Control -->
      <div class="sliders_control">
        <input 
          type="range" 
          :min="min_value" 
          :max="max_value" 
          v-model="sliderMin" 
          @input="updateSliderBackground"
          @change="min_set"
        >
        <input 
          type="range" 
          :min="min_value" 
          :max="max_value" 
          v-model="sliderMax" 
          @input="updateSliderBackground"
          @change="max_set"
          :style="sliderBackground"
        >
      </div>

  </template>
  
  <script>
  export default {
    data() {
      return {
        minAngle: 10,
        maxAngle: 100,
        min_value: 0,
        max_value: 100,
      };
    },
    computed: {
      sliderMin: {
        get() {
          return this.minAngle;
        },
        set(val) {
          val = parseInt(val);
          if (val > this.maxAngle) {
            this.maxAngle = val;
          }
          this.minAngle = val;
          this.updateSliderBackground();
          this.$emit("min_changed", val);
        },
      },
      sliderMax: {
        get() {
          return this.maxAngle;
        },
        set(val) {
          val = parseInt(val);
          if (val < this.minAngle) {
            this.minAngle = val;
          }
          this.maxAngle = val;
          this.updateSliderBackground();
          this.$emit("max_changed", val);
        },
      },
      sliderBackground() {
        const percentageMin = ((this.sliderMin - this.min_value) / (this.max_value - this.min_value)) * 100;
        const percentageMax = ((this.sliderMax - this.min_value) / (this.max_value - this.min_value)) * 100;
        return {
          background: `linear-gradient(to right, rgb(198, 198, 198) 0%, rgb(198, 198, 198) ${percentageMin}%, rgb(254, 35, 124) ${percentageMin}%, rgb(254, 35, 124) ${percentageMax}%, rgb(198, 198, 198) ${percentageMax}%, rgb(198, 198, 198) 100%)`,
        };
      },
    },
    methods: {
        updateSelectedMinMax: function (min, max) {
            this.sliderMax = max
            this.sliderMin = min
        },
        updateMinMax: function (min, max) {
            this.max_value = max
            this.min_value = min
        },
      updateSliderBackground() {
        this.$forceUpdate(); // Force re-render to update background style
      },
      min_set(e) {
        this.$emit("min_set", e.target.value);
      },
      max_set(e) {
        this.$emit("max_set", e.target.value);
      },
    },
    mounted() {
      this.updateSliderBackground();
    },
  };
  </script>
  
  <style scoped>
  /* Add necessary styles for the range sliders and form controls */
  .range_container {
    /* Custom styling if needed */
  }
  
  .sliders_control input[type="range"] {
    /* Custom range slider styling if needed */
  }
  
  .form_control_container__time__input {
    /* Custom input styling if needed */
  }
  </style>
  