<template>
    <div class="trip_selection_wrapper">
        <div class="no_result_found_wrapper">
            <div class="no_rfw_img">
                <img src="/img/no_result_found.svg" alt="">
            </div>
            <div class="text-center py-5">
            <p class="h5 fw_gilroy_medium text-white">No Results Found</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data: function () {
        return {
        };
    },
};
</script>

<style scoped>
</style>