<template>
    <div class="w-100">
        <div class="d-flex flex-column align-items-center">
            <div class="w-100 d-flex justify-content-center">
                Bonus
            </div>

            <div class="w-100 p-1">
                <img src="/img/icons/trippbo.png" width="25" /> {{trippbos}} <!--  <a class="ml-2"
                    style="font-size:12px;color: var(--pink)" href="#">what's trippbos ?</a> -->
            </div>
            
            <p style="font-size:12px;color:gray;">Trippbos are Trippbo's own loyality program points</p>
            <!--      <p style="font-size:12px;color:gray;">Trippbos can currently only be used to enter  <a class="ml-2"
                    style="font-size:12px;color: var(--pink)" href="/travel-lottery" target="_blank">select ticket lotteries</a> , more
                features coming soon.</p> -->
        </div>
    </div>
</template>

<script>


export default {

    data: function () {
        return {
            price: {},
            trippbos: 0
        };
    },

    props: {
        original_order_price: null,
    },


    watch:
    {
        original_order_price: async function (new_value, old) {
            let resp = await axios.post('/bonus/calculate', {price: new_value.price, currency: new_value.currency})
            this.trippbos = resp.data;
        }
    }
};
</script>

<style scoped></style>