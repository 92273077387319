<template>
    <div>
      <!-- Report Popup -->
      <div v-if="showReportPopup" class="user_side_form_box user_side_form_box_active">
        <div @click="closeReportPopup" class="user_side_popup_after"></div>
        <form class="bg-white border_radius_20" id="report-violation-form" @submit.prevent="submitReportForm">
          @csrf
          <input type="hidden" id="report_violating_object" name="violating_object" />
          <input type="hidden" id="report_violating_object_id" name="violating_object_id" />
  
          <!-- user_side_header -->
          <div class="user_side_header mb-4">
            <h4 class="text_dark_blue fw_gilroy_bold mb-0">Report This</h4>
            <div @click="closeReportPopup"><img class="user_side_header_cross" src="/img/icons8_delete.svg" alt="Delete icon"></div>
          </div>
          <!-- 2 -->
          <p class="text_silver mb-3"><img width="24" class="me-2" src="/img/icons8_info.svg" alt="">Help us understand what's happening.</p>
          <div class="d-flex flex-wrap mb-5">
            <a href="#" class="fs_14 interest_tag_item" @click="selectReportReason('Offensive Content', 'Violating Object 1', 12345)">Offensive Content</a>
            <a href="#" class="fs_14 interest_tag_item" @click="selectReportReason('Fraud', 'Violating Object 2', 67890)">Fraud</a>
            <a href="#" class="fs_14 interest_tag_item" @click="selectReportReason('Pornographic Content', 'Violating Object 3', 54321)">Pornographic Content</a>
            <a href="#" class="fs_14 interest_tag_item" @click="selectReportReason('Inappropriate Content', 'Violating Object 4', 98765)">Posting inappropriate things</a>
            <a href="#" class="fs_14 interest_tag_item" @click="selectReportReason('I Want to Help', 'Violating Object 5', 13579)">I want to help</a>
            <a href="#" class="fs_14 interest_tag_item" @click="selectReportReason('Other', 'Violating Object 6', 24680)">Something else</a>
          </div>
          <!-- Hidden Input Field for Selected Reason -->
          <input type="hidden" id="report_reason" name="report_reason" v-model="selectedReason">
          <p class="mb-4 text_silver fs_14"><a href="#" class="text_dark_blue_a text-decoration-none">Learn more</a> about reporting violations of our rules.</p>
          <!-- 3 -->
          <div class="row gx-0 mb-2">
            <button type="submit" class="btn py-2 btn_pink medium_btn d-block col-xl-3 col-lg-4 col-md-5 col-8 ms-auto me-2" aria-label="Submit Report">Save</button>
          </div>
        </form>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';

  export default {
    data() {
      return {
        showReportPopup: false,
        selectedReason: "",
        reportData: {
      report_reason: "",
      violating_object: "",
      violating_object_id: "",
      // Add other fields if needed
    },
      };
    },
    methods: {
      toggleReportPopup() {
        this.showReportPopup = !this.showReportPopup;
      },
      closeReportPopup() {
        this.showReportPopup = false;
      },
      selectReportReason(reason, objectName, objectId) {
  this.selectedReason = reason;

  // Set the selected violating object and object ID directly in the reportData object
  this.reportData.violating_object = objectName.substr(0, 20); // Truncate to 20 characters
  this.reportData.violating_object_id = isNaN(objectId) ? "" : objectId; // Ensure it's numeric
},
submitReportForm() {
  // Create a FormData object to serialize the form data
  const formData = new FormData();
  
  // Add the form fields to the FormData object
  formData.append('violating_object', this.reportData.violating_object);
  formData.append('violating_object_id', this.reportData.violating_object_id);
  formData.append('report_reason', this.selectedReason);
  //formData.append('report_comment', this.reportData.report_comment);

  // You can also add additional data to the formData object here if needed
  formData.append('selectedReason', this.selectedReason);

  axios
    .post('/report-violation', formData)
    .then((response) => {
      // Handle the success response here, if needed
      console.log('Report submitted successfully', response.data);

      // Close the report popup
      this.closeReportPopup();
    })
    .catch((error) => {
      // Handle errors here
      console.error('Error submitting report', error);

      // You can also display an error message to the user if needed
    });
}


    },
  };
  </script>
  
  <style>
  .selected {
    font-weight: bold; /* You can apply any desired styles for selected items */
    background: var(--dark_blue);
  }
  </style>
  